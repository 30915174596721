import React from "react";

const BrandItem = (param) => {
  const position = {
    center: "tooltip-center",
    left: "tooltip-left",
    right: "tooltip-right",
  };

  return (
    <li className="tooltip-outer">
      <img src={param.data} alt="logo"></img>
      <span className={position[param.position]}>
        <span className="rectangle"></span>
        <span className="input">{param.text}</span>
      </span>
    </li>
  );
};

export default BrandItem;
