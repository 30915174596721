import React, { useEffect } from "react";
import "../App.scss";
import imageMe from "../components/images/me_2.jpg";
import imageClimbing from "../components/images/me_climbing.jpg";
import imageView from "../components/images/mountain_view.jpg";
import { Footer } from "../components/Footer.js";
import ClientGoogle from "../components/images/client/logo-google.svg";
import ClientAudi from "../components/images/client/logo-audi.svg";
import ClientChrist from "../components/images/client/logo-christ.svg";
import ClientPaydirekt from "../components/images/client/logo-paydirekt.svg";
import ClientMogul from "../components/images/client/logo-mogul.svg";
import ClientAvado from "../components/images/client/logo-avado.svg";
import ClientProvinzial from "../components/images/client/logo-provinzial.svg";
import ClientElectrolux from "../components/images/client/logo-electrolux.svg";
import ClientVL from "../components/images/client/logo-vertical-life.svg";
import Animation from "../utils/Animation";
import BrandItem from "../components/BrandItem";

function Home() {

  return (
    <div className="content-grid">
      <section id="intro" className="flex-container no-spacing">
        <div className="text-intro border-animation">
          <h1>
            Patrick Dorfer is a <strike>chef</strike>, <strike>developer</strike>, product designer.
          </h1>
        </div>
        <img src={imageMe} alt="image of patrick dorfer" />
      </section>
      <section className="quote flex-container border-animation">
        <div className="container">
          <h2>
            <span>Understanding</span> the customer's product-related {" "}
            <span>problems</span> and vulnerabilities <span>is</span> the {" "}
            <span>key</span> to great product design.
          </h2>
          <p className="overline">“Make the world easier to use.”</p>
        </div>
      </section>
      <section id="about" className="flex-container">
        <div className="no-border_padding">
          <div className="text-right inner-space">
            <p className="subtitle">
              So who am I?
            </p>
            <p>
              I am a simple country guy from Austria who loves solving problems of all kinds, cares about the environment, and lives in Stockholm. 🇸🇪
            </p>
            <p>
              For nearly a decade, I have been creating customer experiences for digital products. Recently also with the connection to the physical world of product design.
            </p>
          </div>
          <h3 className="text-center spacing-xxl">
            I believe design should be <span>rooted in reality</span>, convey an obvious function, <span>relate to people</span>, and is never done.
          </h3>
          <div className="text-right">
            <p>
              Today I work as a senior designer at <a className="link" href="https://www.electroluxgroup.com/en/" target="_blank">Electrolux</a>, creating experiences in the realm of physical and digital products, with a specific emphasis on optimizing interactions within the smart home environment.
            </p>
            <p>
              Previously, I managed the product execution at Vertical-Life <span className="note">(a climbing software company)</span> and supported the Christ Jewellery and Watchmaker's product team as a freelance consultant. Before that, I worked as a UX & product designer for several companies in Germany, such as for Audi at SinnerSchrader.
            </p>
            <img src={imageClimbing} alt="photo of me" />
            <p>
              Besides my job, you usually find me somewhere in nature: climbing some rocks or simply enjoying life outside the city to find calm. I guess I like to stay active.
            </p>
            <p>
              In addition to my enthusiasm for nature, I also have a passion for gastronomy, which is why I unfold entirely in the kitchen to create new dishes with the skills I acquired back then as a chef.
            </p>
          </div>
        </div>
      </section>
      <section className="flex-container brand-listing">
        <ul>
          <BrandItem
            text="Currently, I hold the position of senior designer at Electrolux in the smart home sector."
            position="left"
            data={ClientElectrolux}
          ></BrandItem>
          <BrandItem
            text="In my role, I oversaw the design system, played a key role in the development of myAudi, and led the redesign of the configurator."
            position="center"
            data={ClientAudi}
          ></BrandItem>
          <BrandItem
            text="My responsibilities included conceptual work, design system development, and facilitation of workshops and training sessions."
            position="right"
            data={ClientChrist}
          ></BrandItem>
          <BrandItem
            text="I provided guidance to the Squared Online design team on UX/UI matters and contributed to establishing a comprehensive framework for the entire product."
            position="left"
            data={ClientGoogle}
          ></BrandItem>
          <BrandItem
            text="I spearheaded conceptual efforts for the checkout process and assisted in integrating a design system."
            position="center"
            data={ClientPaydirekt}
          ></BrandItem>
          <BrandItem
            text="During my tenure as a freelance senior product desginer and manager for Vertical-Life, I concentrated on system architecture and design-driven innovation."
            position="right"
            data={ClientVL}
          ></BrandItem>
          {/*<BrandItem
            text="I advised the company in product design and was responsible for the rebranding."
            position="center"
            data={ClientMogul}
          ></BrandItem>
          <BrandItem
            text="I was responsible for quality control of the products and the external development team. Implemented internal processes along with the marketing lead."
            position="center"
            data={ClientAvado}
          ></BrandItem>
          <BrandItem
            text="I helped with the conceptual work for a new product & built a prototype for the MVP."
            position="right"
            data={ClientProvinzial}
  ></BrandItem>*/}
        </ul>
      </section>
      <section className="text-group text-module-2 flex-container">
        <div className="text-first border-animation">
          <p className="overline">Further facts about me:</p>
          <ul>
            <li>Inquisitive mind</li>
            <li>Practice gratitude</li>
            <li>Enjoy time in nature</li>
            <li>Allow my mind to wander</li>
          </ul>
          <br></br>
          <p className="overline second">My current books:</p>
          <ul>
            <li>The daily laws</li>
            <li>Marcus Aurelius</li>
            <li>Discipline is destiny</li>
            <li>12 rules for life</li>
          </ul>
        </div>
        <div className="text-last border-animation">
          <img src={imageView} />
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Home;
