import BorderBoxWatcher from "./BorderBoxWatcher.js";

const MAX_DISPLAY_WIDTH = 767; // Breakpoint for mobile devices
const NAV_BORDER_WIDTH = 4;
const NAV_HEIGHT_MOBILE = 68;
const NAV_HEIGHT_DESKTOP = 100;
const NAV_TRANS_SPEED = 1.5;
const Border_color = "#1F1F1F";

class Animation {
  constructor() {
    this.animationElements = null;
    this.navBar = null;
    this.navUl = null;

    this.scroll = {
      maxScroll: 0,
      scrollableHeight: 0,
      currentPos: 0,
      lastPos: 0,
      lastDownPos: 0,
      lastUpPos: 0,
    };

    this.nav = {
      height: 0,
      top: 0,
      lastTop: 0,
      lastBottom: 0,
    };

    this.borderAnimationBoxHeight = 0;
  }

  handleNavPosition() {
    this.updateElements();
    let isScrollingDown = this.scroll.lastPos < window.pageYOffset;
    let navIsCompletelyHidden = !(this.nav.top - 1 >= -this.nav.height);
    let navIsCompletelyVisible = !(this.nav.top + 1 <= 0);

    if (isScrollingDown) {
      this.scroll.lastDownPos = window.pageYOffset;
      if (navIsCompletelyHidden) {
        this.nav.top = -this.nav.height;
      } else {
        this.nav.top =
          this.nav.bottom +
          (this.scroll.lastUpPos - window.pageYOffset) * NAV_TRANS_SPEED;
      }
      this.nav.lastTop = this.nav.top;
    } else {
      this.scroll.lastUpPos = window.pageYOffset;
      if (navIsCompletelyVisible) {
        this.nav.top = 0;
      } else {
        this.nav.top =
          this.nav.lastTop +
          (this.scroll.lastDownPos - window.pageYOffset) * NAV_TRANS_SPEED;
      }
      this.nav.bottom = this.nav.top;
    }

    // Reach top or bottom of page = show nav
    if (
      this.scroll.scrollableHeight <= window.pageYOffset ||
      window.pageYOffset <= 1 ||
      this.scroll.maxScroll <= window.innerHeight + window.pageYOffset + 10
    ) {
      this.nav.top = 0;
    }

    // sm display = nav disapears on bottom; lg display = nav disapears on top
    if (window.innerWidth > MAX_DISPLAY_WIDTH)
      this.navUl.style.transform = `matrix(1, 0, 0, 1, 0, ${this.nav.top})`;
    else this.navUl.style.transform = `matrix(1, 0, 0, 1, 0, ${-this.nav.top})`;

    this.scroll.lastPos = window.pageYOffset;
  }

  handleNavBorder() {
    if (window.innerWidth > MAX_DISPLAY_WIDTH) return;
    const navShouldBeVisible = this.watcher.watchBoxes(
      this.borderAnimationBoxHeight - this.nav.top
    );
    if (navShouldBeVisible)
      this.navBar.style.borderTop = `4px solid ${Border_color}`;
    else this.navBar.style.borderTop = "4px solid transparent";
  }

  updateElements() {
    this.borderAnimationBoxHeight =
      window.innerHeight - this.navBar.offsetHeight + NAV_BORDER_WIDTH;

    this.scroll.maxScroll = Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
    );
    this.scroll.scrollableHeight = this.scroll.maxScroll - window.innerHeight;

    if (window.innerWidth <= MAX_DISPLAY_WIDTH) {
      this.nav.height = NAV_HEIGHT_MOBILE;
    } else {
      this.nav.height = NAV_HEIGHT_DESKTOP;
      this.navBar.style.borderTop = `4px solid ${Border_color}`;
    }
  }

  start() {
    this.initElements();
    this.initEventListener();
    this.updateElements();
    this.handleNavPosition();
    this.handleNavBorder();
  }

  initElements() {
    this.animationElements = document.querySelectorAll(".border-animation");
    this.watcher = new BorderBoxWatcher(this.animationElements);
    this.navBar = document.querySelectorAll(".mainmenu")[0];
    this.navUl = document.querySelectorAll("nav")[0];
  }

  initEventListener() {
    window.onresize = (e) => {
      this.updateElements();
    };
    window.onscroll = (e) => {
      this.handleNavPosition();
      if (window.innerWidth <= MAX_DISPLAY_WIDTH) this.handleNavBorder();
    };
  }
}

export default Animation;
