import React, { Component } from "react";
import mail from "../data/mail.json";

export class Footer extends Component {
  componentDidMount() {
    const button = document.getElementById("mailTo");

    button.addEventListener("click", (e) => {
      e.preventDefault();
      console.log("press buttons");

      window.open(
        "mailto:" +
        mail.name +
        mail.trennzeichen +
        mail.server +
        "." +
        mail.land
      );
    });
  }

  render() {
    return (
      <footer>
        <div className="container">
          <div className="flex-container">
            <p className="p-small">You made it this far!</p>
            <div id="social-listing">
              <ul>
                <li>
                  <a id="mailTo" href="#" className="link">
                    Contact
                  </a>
                </li>
                <li>
                  <a
                    className="link"
                    href="https://www.linkedin.com/in/dedorfer/"
                    target="_blank"
                  >
                    LinkedIn
                  </a>
                </li>
                {/*<li><a className="link" href="https://www.instagram.com/patrickdedorfer/" target="_blank">INSTAGRAM</a></li>
                                <li><a className="link" href="hhttps://medium.com/@dedorfer" target="_blank">MEDIUM</a></li>*/}
              </ul>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
