import BorderBox from "./BorderBox";

export default class BorderBoxWatcher {
  constructor(boxes) {
    this.boxes = [];
    boxes.forEach((element) => {
      this.boxes.push(new BorderBox(element));
    });
  }

  watchBoxes(scrollPos) {
    const status = this.boxes.filter((el) => el.update(scrollPos));
    return status.length >= 1 ? true : false;
  }
}
