export default class Box {
  constructor(el) {
    this.el = el;
  }

  update(scrollPosition) {
    const { top, bottom } = this._el.getBoundingClientRect();
    return scrollPosition >= top && scrollPosition <= bottom ? true : false;
  }

  set el(el) {
    this._el = el;
  }
  get el() {
    return this._el;
  }
}
