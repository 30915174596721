import React from "react";
import "../App.scss";
import ReactLogo from "./images/signature_form.svg";
import ReactLayer from "./images/signature.svg";

function Logo() {
  return (
    <div className="logo-wrapper">
      <div className="logo"><object type="image/svg+xml" data={ReactLogo}></object></div>
      <div className="layer" to="/">
        <div className="inner"><object type="image/svg+xml" data={ReactLayer}></object></div>
      </div>
    </div>
  );
}

export default Logo;
