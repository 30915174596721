import React from "react";
import "./App.scss";
//import Nav from "./Nav";
import Logo from "./components/Logo";
import Home from "./pages/Home";
import Audi from "./pages/Audi";
import Paydirekt from "./pages/Paydirekt";
import Christ from "./pages/Christ";
import ScrollToTop from "./components/ScrollToTop";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  return (
    <Router onUpdate={() => window.scrollTo(0, 1)}>
      <div className="App">
        <Logo />
        {/*<Nav />*/}
        <ScrollToTop />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/audi" component={Audi} />
          <Route path="/christ" component={Christ} />
          <Route path="/paydirekt" component={Paydirekt} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
