import React, { useEffect } from "react";
import "../App.scss";
import { Footer } from "../components/Footer";
import Animation from "../utils/Animation";

function Audi() {
  let animation = new Animation();

  useEffect(() => {
    animation.initElements();
    animation.start();
  });

  return (
    <div>
      <div id="project-intro" className="flex-container">
        <div className="container full-centered">
          <div className="text-headline-body">
            <h2>
              I was responsible for the <span>design system</span>, part of the
              development of <span>myAudi</span> &amp; redesigning the
              configurator.
            </h2>
            <p className="overline bold">PRODUCT DESIGNER — SINNERSCHRADER</p>
          </div>
        </div>
      </div>
      <div className="project-description flex-container border-animation">
        <div className="container">
          <div className="text-headline-body">
            <h1>myAudi</h1>
            <p>
              Get real-time information about your vehicle at any time and keep
              a constant eye on your fuel tank level, driving range, service
              appointments, warnings and much more. Comfortably plan journeys in
              the app from your own home to send destinations and routes
              directly to your vehicle. You can also remotely adjust the climate
              control and open/close vehicle doors.
            </p>
            <a className="link" href="https://my.audi.com/" target="_blank">
              VISIT WEBSITE
            </a>
          </div>
        </div>
      </div>
      <div className="image-module bg-white border-animation">
        {/*<img src={ImgAudi01} />*/}
      </div>
      <div className="text-group text-module-2 flex-container border-animation">
        <div className="text-first">
          <p className="overline bold">AUDI'S REQUEST</p>
          <p>
            Develope myAudi and make it the main platform for the customer,
            dealer and manufacturer and furthermore:
          </p>
          <ul>
            <li>
              Worldwide expansion and scaling of the Audi mobility concept
            </li>
            <li>Digital services as part of corporate strategy</li>
          </ul>
        </div>
        <div className="text-last">
          <p className="overline bold">CHALLENGES</p>
          <p>
            The primary (UX/UI) hurdle was to ensure a simple and easy-to-use
            experience on every screen while allowing multiple levels of
            content, services, and navigation. Several testing phases and
            revisions were required to achieve that balance. We needed to find a
            unified way to accommodate all the services as compact as possible.
          </p>
        </div>
      </div>
      <div className="text-group text-module flex-container project-main-part">
        <div className="container no-border_padding">
          <div className="text-headline-body">
            <div>
              <h2>The project.</h2>
              <p>
                Together with a small team of product designers, project
                managers and developers, we brought myAudi into life. The
                digital access to your Audi. Anywhere. Anytime. This app
                connects your Audi with your everyday life and brings more
                driving comfort into it, with innovative functions and services.
              </p>
              <div className="image-module inner">
                {/*<img src={ImgAudi03} />*/}
              </div>
            </div>
            <p>
              In the first step, we used the Design-Thinking method to come up
              with the idea of a global interface for connecting your vehicle
              and all digital services, and how to bring it into a realization:
            </p>
            <ul>
              <li>🤷🏼‍♂️ Defined the difficulties.</li>
              <li>🧠 Identified customers needs and had to understand them.</li>
              <li>📝 Developed ideas, sketched and prioritized solutions.</li>
              <li>👨🏼‍💻 Prototyped the best ideas.</li>
              <li>🕹 Finally, there was the test phase.</li>
            </ul>
            <p>
              Afterwards, we were able to incorporate all the findings and
              resulting statements from different people into the concept. After
              that, we worked in two weeks of sprints where we came up with
              results faster, worked on new features, and could directly
              integrate development. We wanted to test some parts of the app
              with live-data to see how users interact with their data and their
              content because it's much closer to reality, and the insights are
              richer from observing them.
              <br />
              <br />
              So for every sprint, we covered a big topic like the vehicle
              status or the dashboard in which we came up with some useful
              features and new services that we directly tackled.
              <br />
              <br />
              Then, we present the newly developed work to Audi. If there was no
              further feedback, we planned the next user test to determine
              whether there is a need for it from the user's point of view.
            </p>
          </div>
        </div>
      </div>
      <div className="video-module inner border-animation">
        <iframe
          src="https://www.youtube.com/embed/6qaIPt06ocY"
          frameBorder={0}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
      <div className="text-module flex-container transparent-bg no-border">
        <div className="container no-border_padding">
          <div className="text-headline-body">
            <h2>Structure comes to life.</h2>
            <p>
              After finalizing the complete dashboard, account and map for the
              car & dealer search, a product designer and I started with the
              first steps of the design system.
            </p>
            <p>
              The time was long overdue for creating a global framework to get a
              better overview of all existing components and their behaviour
              because there were already other projects besides myAudi, which
              also grew very fast.
            </p>
            <p>
              The difficulty was to revise an existing style guide and GUI kit
              so that we could ensure a flexible application that did not
              completely limit us. So we defined the foundations for the system,
              started to specify the already created components and collected
              everything from the various projects to discuss it in our weekly
              meeting. It was essential to have people responsible for the
              system to avoid chaos and inconsistency, and for that, we slowly
              turned from a distributed model to a centralized model. So we had
              one team in charge of the system and made it evolve. They
              facilitated the work of the other squads and had to be very close
              to them, to be sure that the system covers most of their needs.
            </p>
            <p>
              So we had gathered all the news and problems from the various Audi
              streams to have them incorporated into the framework, after
              consultation and coordination with Audi.
            </p>
          </div>
        </div>
      </div>
      <div className="text-module conclusion flex-container border-animation">
        <div className="container">
          <div className="text-headline-body">
            <p className="overline bold">Learnings</p>
            <p>
              It is never easy for a politically driven corporation like Audi to
              develop a product where all good ideas and thoughts can be
              implemented without any problems.
            </p>
            <p>
              What I learned was that the customer should be very closely
              involved in getting as much out of a picture as possible because
              weekly arrangements are not enough. Also, a great advantage is a
              direct exchange to have no barrier between customer and service
              provider - it should feel like a unity.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Audi;
